import React from 'react';

export const WoodBasedMaterialsIcon = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect x="0.533333" y="0.533272" width="14.9333" height="14.9333" rx="0.533333" stroke={props.color || "black"} strokeWidth="1.06667" />
			<path d="M2.6665 13.8838L2.6665 7.84981" stroke={props.color || "black"} strokeWidth="1.06667" strokeLinecap="round" />
			<path d="M13.333 9.21729L13.333 3.18331" stroke={props.color || "black"} strokeWidth="1.06667" strokeLinecap="round" />
			<path d="M4.46655 14L4.46655 4" stroke={props.color || "black"} strokeWidth="1.06667" strokeLinecap="round" />
			<path d="M11.4666 10.9999L11.4666 2.99994" stroke={props.color || "black"} strokeWidth="1.06667" strokeLinecap="round" />
			<path d="M7.46667 7L7.46655 1" stroke={props.color || "black"} strokeWidth="1.06667" strokeLinecap="round" />
			<path d="M7.46667 15.5L7.46655 10" stroke={props.color || "black"} strokeWidth="1.06667" strokeLinecap="round" />
			<path d="M9.46655 15L9.46655 1" stroke={props.color || "black"} strokeWidth="1.06667" strokeLinecap="round" />
		</svg>
	);
};
