

export const GRID_CHECKBOX_COL_DEF = {
  field: 'checkbox',
  editable: false,
  sortable: false,
  hideSortIcons: true,
  width: 100,
  headerClassName: 'bg-gray-30',
  disableExport: true,
}
