import React from 'react';

export const InsulationMaterialIcon = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect x="0.533333" y="0.533333" width="14.9333" height="14.9333" rx="0.533333" stroke={props.color || "black"} strokeWidth="1.06667" />
			<path d="M2.66662 14.9999L2.6665 0.716579" stroke={props.color || "black"} strokeWidth="1.06667" strokeLinecap="round" />
			<path d="M13.4666 15.5007L13.4667 1.00018" stroke={props.color || "black"} strokeWidth="1.06667" strokeLinecap="round" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.8261 10.1736C7.39389 10.1736 7.04349 10.524 7.04349 10.9562C7.04349 11.3884 7.39389 11.7388 7.8261 11.7388H9.39131C9.82352 11.7388 10.1739 12.0892 10.1739 12.5214C10.1739 12.9536 9.82352 13.3041 9.39131 13.3041H7.8261C6.52941 13.3041 5.47827 12.2529 5.47827 10.9562C5.47827 9.65954 6.52941 8.6084 7.8261 8.6084C8.2583 8.6084 8.60871 8.9588 8.60871 9.39101C8.60871 9.82321 8.2583 10.1736 7.8261 10.1736Z"
				fill={props.color || "black"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.82617 7.04324C8.25838 7.04324 8.60878 7.39365 8.60878 7.82585C8.60878 8.25806 8.25838 8.60846 7.82617 8.60846V10.1737C9.12286 10.1737 10.174 9.12254 10.174 7.82585C10.174 6.52916 9.12286 5.47803 7.82617 5.47803V7.04324Z"
				fill={props.color || "black"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.8261 3.91287C7.39389 3.91287 7.04349 4.26328 7.04349 4.69548C7.04349 5.12769 7.39389 5.47809 7.8261 5.47809C8.2583 5.47809 8.60871 5.8285 8.60871 6.2607C8.60871 6.6929 8.2583 7.04331 7.8261 7.04331C6.52941 7.04331 5.47827 5.99217 5.47827 4.69548C5.47827 3.39879 6.52941 2.34766 7.8261 2.34766H9.39131C9.82352 2.34766 10.1739 2.69806 10.1739 3.13026C10.1739 3.56247 9.82352 3.91287 9.39131 3.91287H7.8261Z"
				fill={props.color || "black"}
			/>
		</svg>
	);
};
