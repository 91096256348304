import {ColdIcon, ErrorFallback} from "@coldpbc/components"
import { IconNames } from "@coldpbc/enums"
import {withErrorBoundary} from "react-error-boundary";
import React from "react";


const _SustainabiliBuddyButton = (props: {
  showPromptContainer: boolean,
  setShowPromptContainer: (value: boolean) => void,
}) => {
  const { showPromptContainer, setShowPromptContainer } = props
  const show = showPromptContainer ? (
    <ColdIcon name={IconNames.CloseModalIcon} className={'w-[20px] h-[20px]'} />
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M29.8711 11.5806C31.4234 11.5806 32.8116 12.5471 33.3503 14.0029L34.5166 17.1549C35.9444 21.0135 38.9867 24.0557 42.8452 25.4835L45.9972 26.6499C47.4531 27.1886 48.4195 28.5767 48.4195 30.129C48.4195 31.6813 47.4531 33.0694 45.9972 33.6081L42.8452 34.7745C38.9867 36.2023 35.9444 39.2445 34.5166 43.1031L33.3503 46.2551C32.8116 47.7109 31.4234 48.6774 29.8711 48.6774C28.3189 48.6774 26.9307 47.7109 26.392 46.2551L25.2257 43.1031C23.7979 39.2445 20.7556 36.2023 16.8971 34.7745L13.745 33.6081C12.2892 33.0694 11.3228 31.6813 11.3228 30.129C11.3228 28.5767 12.2892 27.1886 13.745 26.6499L16.8971 25.4835C20.7556 24.0557 23.7979 21.0135 25.2257 17.1549L26.392 14.0029C26.9307 12.5471 28.3189 11.5806 29.8711 11.5806ZM29.8711 15.2903L28.7048 18.4423C28.5776 18.7861 28.4402 19.1247 28.2931 19.4579C28.1481 19.7861 27.9936 20.1091 27.83 20.4264C26.1346 23.7132 23.4554 26.3925 20.1685 28.0878C19.8512 28.2515 19.5282 28.406 19.2 28.551C18.8668 28.6981 18.5282 28.8355 18.1845 28.9627L15.0324 30.129L18.1845 31.2954C18.5282 31.4226 18.8668 31.5599 19.2 31.7071C19.5283 31.852 19.8512 32.0065 20.1685 32.1702C23.4554 33.8656 26.1346 36.5448 27.83 39.8317C27.9936 40.149 28.1481 40.4719 28.2931 40.8002C28.4402 41.1333 28.5776 41.4719 28.7048 41.8157L29.8711 44.9677L31.0375 41.8157C31.1647 41.4719 31.302 41.1333 31.4492 40.8002C31.5942 40.4719 31.7487 40.149 31.9123 39.8317C33.6077 36.5448 36.2869 33.8656 39.5738 32.1702C39.8911 32.0065 40.214 31.852 40.5423 31.707C40.8754 31.5599 41.2141 31.4226 41.5578 31.2954L44.7099 30.129L41.5578 28.9627C41.2141 28.8355 40.8754 28.6981 40.5423 28.551C40.214 28.406 39.8911 28.2515 39.5738 28.0878C36.2869 26.3925 33.6077 23.7132 31.9123 20.4264C31.7486 20.1091 31.5942 19.7861 31.4492 19.4579C31.302 19.1247 31.1647 18.7861 31.0375 18.4423L29.8711 15.2903Z"
            fill="url(#paint0_linear_46_2130)"/>
      <defs>
        <linearGradient id="paint0_linear_46_2130" x1="29.8711" y1="11.5806" x2="29.8711" y2="48.6774"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#75D8F9"/>
          <stop offset="1" stopColor="#D7F250"/>
        </linearGradient>
      </defs>
    </svg>
  )
  return (
    <div
      className="p-[2px] w-[69px] h-[69px] rounded-full bg-gradient-to-br from-yellow-250 via-lightblue-400 to-primary-300 cursor-pointer"
      onClick={() => setShowPromptContainer(!showPromptContainer)}
    >
      <div className="rounded-full h-full w-full bg-bgc-accent flex flex-row items-center justify-center">
          {show}
      </div>
    </div>
  )
}

export const SustainabiliBuddyButton = withErrorBoundary(_SustainabiliBuddyButton, {
  FallbackComponent: props => <ErrorFallback {...props} />,
  onError: (error, info) => {
    console.error('Error occurred in SustainabiliBuddyButton: ', error);
  },
});
